/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/** Variables **********************************************/
$text-font-family:              'Overpass', Helvetica, sans-serif;
$base-font-family:              'Martel', Georgia, sans-serif; 
$base-line-height:              1.5em;
$horizontal-spacing-unit:       50px;
$vertical-spacing-unit:         40px;
$nav-height:                    100px;
$base-font-size: 12pt;
$small-font-size: 10pt;
$title-font-size: 18pt;
$foot-height: 100px;
$toggle-menu-width: 500px;
$img_spacing: 5px; 

html {
    --background-color: #fff;
    --transparent-background-color: rgba(255,255,255,0.2);
    --semitransparent-background-color: rgba(255,255,255,0.3);
    --background-contrast-color: #f0f0f0;
    --background-contrast-color-border: #e0e0e0; // color.scale(#fff, $lightness: -50%);
    
    --dark-text-color: #000;
    --text-color:     #333;
    --dimmed-text-color: #555;
    --dimmed-background-color: #f9f9f9; 
    --theme-color:   rgb(0,150,190);
}


@media (prefers-color-scheme: dark) {
    html {
        --background-color: #111;
        --transparent-background-color: rgba(17,17,17,0.2);
        --semitransparent-background-color: rgba(19,19,19,0.3);
        --background-contrast-color: #222;
        --background-contrast-color-border: #555;

        
        --dark-text-color: #fff;
        --text-color:     #ccc;
        --dimmed-text-color: #999;
        --dimmed-background-color: #222; 
        --theme-color:   rgb(0,180,228);
    }
}


/************************************************************/

/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 100;
    color: var(--text-color);
    background-color: var(--background-color);
    -webkit-text-size-adjust: 100%;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $vertical-spacing-unit / 2;
}

hr{
    /* Inset, by Dan Eden */
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

}


/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $base-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $horizontal-spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: 100;
    line-height: 1.2em;
}


/**
 * Simple formats
 */
strong {
    font-weight: bold;
}


/**
 * Links
 */
a {
    color: var(--text-color);
    text-decoration: underline; 
    &:hover {
        color: var(--theme-color);
        text-decoration: none;
    }
}

article a {
    font-weight: 100;

    &:hover {
        text-decoration: none;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: var(--text-color);
    border-left: 10px solid var(--dimmed-text-color);
    padding-left: $horizontal-spacing-unit / 2;
    font-size: 18px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
code {
    border-radius: 3px;
    background-color: var(--background-contrast-color);
    border: 1px solid var(--background-contrast-color-border);
    border-radius: 2px;
}

code, pre {
    font-family: 'Source Code Pro', 'Fira Mono', 'Andale Mono', 'Consolas', 'Monaco', monospace;
    font-weight: 400;
}

figure.highlight code, code.highlighter-rouge {
    font-size: 10pt;
}

figure.highlight pre, pre.highlight {
    font-size: 10pt;
    border-radius: 4px;
    background-color: var(--background-contrast-color);
    border: 1px solid var(--background-contrast-color-border);
    border-radius: 2px;
    line-height: 1.4em;
}

td.rouge-gutter {
    border-right: 1px solid var(--background-contrast-color-border);
}

pre.lineno {
    opacity: 0.4;
    /* padding right 3px, otherwise 0 */
    padding: 0.3em; /* 0 3px 0 0; */
    text-align: right;
    font-size: 9pt;
}

pre code {
    background: none
}

code {
    padding: 1px 5px;
}

pre {
    padding: 4px 6px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}


/**
 * Wrapper
 */ 

.wrapper {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
    @extend %clearfix;
}


/**
 * Clearfix
 */
%clearfix {
    &:before,
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



