
/**
 * Site header   **********************************************************
 */

.header-wrapper {
    position:absolute;
    display: block;
    left:0px;
    top:0px;
    height: $nav-height + 100;
    width:100%;
    z-index: 50;
    padding: 0;
}

.menuopen .header-wrapper {
    height: $nav-height;
}

.site-header {
    background-color: var(--transparent-background-color);
    border-bottom: 1px solid var(--semitransparent-background-color);
    position:sticky;
    left:0px;
    top:0px;
    height:$nav-height;
    width:100%;
    z-index: 50;
    padding: 0;
}

.site-title {
    position: relative;
    left: 5%;
    line-height: $nav-height;
    float: left;
    font-size: 18px;
    a {
        color: var(--text-color) !important;        
    }
    .page-link{
        line-height: $base-line-height;
    }
}

.site-header, .mobile-menu {
    a {
        color: var(--dimmed-text-color);
        text-decoration: none;
    }
    a:hover, a:active, a.active {
        color: var(--dark-text-color);
    }
}

.site-header .site-nav {
    position: absolute;
    right: 5%;
    float: right;
}

.site-nav {
    font-size: 18px;

    line-height: $nav-height;
    .page-link{
        line-height: $base-line-height;
        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 10px;
        }
    }
    a:hover, a:active, a.active {
        // Webkit/Safari doesn't support all-in-one text-decoration declaration
        text-decoration: underline;
        text-decoration-color: var(--theme-color);
        text-decoration-style: solid;
        text-decoration-thickness: 2px;
        text-underline-offset: 6px;
    }
    a#togglemenu {
        text-decoration: none;
    }
}

@media only screen and (max-width: $toggle-menu-width) {
    .site-nav .desktop-menu {
        display: none;
    }
}

/**
 * Burger menu for mobile
 */

@keyframes span1 {
    0%   { top: 0%; transform-origin: 50% 50%;}
    50%  { top: 50%; transform: rotate(0deg) translateY(-50%);}
    100% { top: 50%; transform: rotate(45deg) translateY(-50%);}
}
@keyframes span2 {
    0%   {opacity: 1;}
    45%  {opacity: 1;}
    65%  {opacity: 0;}
    100% {opacity: 0;}
}
@keyframes span3 {
    0%   { bottom: 0%; transform-origin: 50% 50%;}
    45%  { bottom: 50%; transform: rotate(0deg) translateY(50%);}
    100% { bottom: 50%; transform: rotate(-45deg) translateY(50%);}
}

#togglemenu {display: none;}

@media only screen and (max-width: $toggle-menu-width) {
    #menu {display: none;}
    .menuopen #menu {display: block;}
    #togglemenu {display: block; cursor: pointer;} 
}
#togglemenu > span {display: inline-block; margin-left: 0.4rem; width: 1.1rem; height: 0.75rem; position: relative; vertical-align: middle; position: relative; bottom: 1px;}
#togglemenu > span > span {display: block; position: absolute; width: 100%; height: 2px; background: #999;}
#togglemenu > span > span:nth-child(1) {top: 0; transform-origin: 50% 0%;}
#togglemenu > span > span:nth-child(2) {top: 50%; margin-top: -1px;}
#togglemenu > span > span:nth-child(3) {bottom: 0; transform-origin: 50% 100%;}

.menuopen #togglemenu > span > span:nth-child(1) {
    animation: span1 0.25s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.menuopen #togglemenu > span > span:nth-child(2) {
    animation: span2 0.25s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;  
}
.menuopen #togglemenu > span > span:nth-child(3) {
    animation: span3 0.25s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards; 
}

.mobile-menu {
   visibility: hidden;
}

.mobile-menu .site-nav {
    padding-top: $nav-height;
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    width: 100%;
    height: 100%;
    .page-link {
        margin: 0 !important;
    }
    a {
        line-height: $nav-height;
    }
    a:hover, a:active, a.active {
        background-color: var(--background-color);
        display: inline-block;
        border-width: 0;
    }
}
.menuopen .mobile-menu {
    visibility: visible;
}
.mobile-menu {
    position: fixed;
    background-color: var(--dimmed-background-color);
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@media only screen and (max-width: 600px) {    
    .desktoponly {
        display: none;
    }
}



/**
 * Site footer  **********************************************************
 */
.site-footer {
    font-size: 16px;
    background-color: var(--dimmed-background-color);
    color: #aaa;
    clear: both;
    margin-top: -$foot-height;
    position: absolute;
    left:0px;
    bottom:0px;
    width:100%;
    min-height: $foot-height;
    font-family: $text-font-family !important;

    a {
        color: #aaa;
    }


    .copyright {
        line-height: $foot-height;
        position: relative;
        float: left;
        left: 5%;
        padding: 0;
        margin: 0;
    }

    .contacticon {
        line-height: $foot-height;
        position: relative;
        float: right;
        right: 5%;
        padding: 0;
        margin: 0;
    }

    p {
        margin-bottom: 0; // Remove margin set in _sass/_base.scss for vertical rythm
    }
}

/**
 * Pagination   **********************************************************
 */
.pagination {
    width: 100% !important;
    background-color: var(--dimmed-background-color);
    display: block;

    .previous {
        position: absolute;
        left: 5%;
        float: left;
    }
    .next {
        position: absolute;
        right: 5%;
        float: right;
    }
    .pagination-inactive {
        opacity: 0.5;
    }
}

.paginationicon {
    font-size: 50px;
    
    a {
        color: var(--theme-color);
    }
}

.page-container {
    position: relative;
    min-height: 100vh;
}

/**
 * Page content   **********************************************************
 */
.page-content {
    padding: 0;
    padding-top: $nav-height; /* VERTICAL PADDING FOR TITLE ON EVERY PAGE */
    padding-bottom: $foot-height; // for footer on bottom
}
.post-list {
    margin: 0px 0;
    list-style: none;
    > li {
        margin-bottom: $vertical-spacing-unit;
    }
    h1,h2,h3,h4,h5,h6 {
        font-family: $text-font-family !important;
    }
    h2 {
        font-size: 32px;
        font-weight: bold;
        margin-top: 0px !important;
    }
    p {
        margin: 0 !important;
        color: var(--text-color);
    }
    a {
        text-decoration: none;
    }
}

.contacticon {
    font-size: 30px;
    display:block;
    margin: 10px;
}

/**
 * Posts  **********************************************************
 */
.post-header {
    margin-top: $vertical-spacing-unit/2.0;
    margin-bottom: $vertical-spacing-unit;
    text-align: center;
}
.post-title {
    font-size: 28pt;
    letter-spacing: -1px;
    line-height: 1;
}
a.post-title {
    text-decoration: none;
}
.post-content {
    font-family: $text-font-family;
/*    font-weight: 900; */
    p {
        margin-bottom: 2em;
    }
    h1, h2, h3, h4, h5, h6 {
        font-family: $base-font-family;
        margin-top: 2.1em;
        margin-bottom: 0.4em;
    }
    h1 {
        font-size: 26pt;
    }
    h2 {
        font-size: 22pt;
    }
    h3 {
        font-size: 14pt;
    }
    h4 {
        font-size: 10pt;
    }

    ul, ol {
        margin-left: 1em !important;
    }

}

.post-content {
    margin-left: 10%;
    margin-right: 10%;
    line-height: 2em;
}
@media (max-width: 700px) {
    .post-content {
        margin-left: 5%;
        margin-right: 5%;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: var(--dimmed-text-color) !important;
    margin-bottom: 0px; 
}


/** 
 * Portfolio grid **********************************************************
*/
// Nicolas Gallagher's micro clearfix hack
// http://nicolasgallagher.com/micro-clearfix-hack/
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}


ul.portfolio {
    list-style-type: none;
    margin: 0;
    padding: 0;
    line-height: 0;
    width: 100%;
    
    display: grid; 
    grid-template-columns: repeat(4, 1fr);
    font-family: $text-font-family;
}

@media (max-width: 1290px) {
    ul.portfolio { grid-template-columns: repeat(3, 1fr); }
}
@media (max-width: 960px) {
    ul.portfolio { grid-template-columns: repeat(2, 1fr); }
}
@media (max-width: 600px) {
    ul.portfolio { grid-template-columns: repeat(1, 1fr); }
}

ul.portfolio li {
    position: relative;
}

.portfolio div.info {
    line-height: 1.2em;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

div.info {
    padding-top: 1em;
    opacity: 0.0;
    transition-duration:         .2s;
    -moz-transition-duration:    .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration:      .2s;

    .project-name {
        font-size: 24px;
        font-weight: 400;
        color: #fff;
        padding-bottom: 0.25em;
    }
    
    .project-date {
        font-size: 18px;
        font-weight: 100;
        color: #ccc;
    }
}

a:hover div.info, a:active div.info {
    opacity: 1.0;
} 

.portfolio .thumbnail {
    width: 100% !important;
    height: auto !important;
}

li.darken {
    background-color: #000;

    a:hover div.info, a:active div.info {
        background-color: rgba(0,0,0,0.5);
        z-index: 20;
    }
    
    a:hover img, a:active img {
        z-index: 1;
    }
}

/** 
 * Portfolio pages **********************************************************
*/

figcaption {
    font-size: 0.8em;
    line-height: 1.5em;
}

.gallery {
    display: flex;
    min-width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;

    margin: 20px -5px; /* top/bottom left-right */
}

/* for galleries where we want the height to be equal for the whole thing.
   Note that in that case we might end up with images cropped due to object-fit: cover below
 
   That's what we want as it's better than images being distorted.
*/
.fit-height img {
    min-height: 100%;
    
}

.gallery .gallery-item {
    padding: 5px;
    flex: 1 0 24%;
}
                           
.gallery .gallery-item img {
    max-width: 100%;
    object-fit: cover;
}

@media (max-width: 1290px) {
    .gallery { flex-wrap: wrap; }
    .gallery .gallery-item { flex: 1 0 40%; }
}
@media (max-width: 960px) {
}
@media (max-width: 600px) {
    .gallery { margin: 20px 0; }
    .gallery .gallery-item { flex: 1 0 100%; }
    .gallerysmall .gallery-item { flex: 1 0 40% !important; }
}

.rowcol{ 
    width: 100%;
    height: 100%;
    float: left;
    object-fit: cover;
    box-sizing:border-box;
    padding: $img_spacing;
}

.wide-image-row {
    width: 100%;
    margin-bottom: $vertical-spacing-unit/2;
    justify-content: space-around;
    display: flex;

    img {
        max-width: 100%;
        margin: 0;
        padding: 0;
        align-self: flex-start; /* fixes Safari image stretch */
    }
}

.alignright {
    float: right;
    display: block;
    max-width: 50%;

    img {
        max-width: 100%;
        margin: 0;
        padding: 0;
    }
}

@media (max-width: 700px) {
    .alignright {
        max-width: 100% !important;
    }
}

.wide-video-row {
    width: 100%;
    margin-bottom: $vertical-spacing-unit/2;
    justify-content: space-around;

    img {
        max-width: 100%;
        margin: 0;
        padding: 0;
    }
}

.columnstwo {
    line-height: 0;
    
    -webkit-column-count: 2;
    -webkit-column-gap:   0px;
    -moz-column-count:    2;
    -moz-column-gap:      0px;
    column-count:         2;
    column-gap:          10px;
}

.columnstwo .columns-col {
    margin: 0px;
    margin-bottom: 10px;
}

.columnstwo .columns-col, .columnstwo img, .columnstwo iframe {
    width: 100%;
    height: auto;
    min-height: 50px;
}

@media (max-width: 700px) {
    .columnstwo {
        -webkit-column-count: 1;
        -webkit-column-gap:   0px;
        -moz-column-count:    1;
        -moz-column-gap:      0px;
        column-count:         1;
        column-gap:           0px;  
    }
}

.videowrapper {
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}
.videowrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/** 
 * Lab **********************************************************
 * based on https://css-tricks.com/seamless-responsive-photo-grid/
 *
 * If we have a very new browser (post-2021) we can use masonry CSS?
 * https://www.smashingmagazine.com/native-css-masonry-layout-css-grid/
 */
#lab {    
    /* Prevent vertical gaps */  
    line-height: 0;
}

 /*  
  -webkit-column-count: 4;
  -webkit-column-gap:   0px;
  -moz-column-count:    4;
  -moz-column-gap:      0px;
  column-count:         4;
  column-gap:           0px;
  flex-direction: column;
*/
/*
@media (max-width: 1290px) {
  #lab {
  -moz-column-count:    3;
  -webkit-column-count: 3;
  column-count:         3;
  }
}
@media (max-width: 960px) {
  #lab {
  -moz-column-count:    2;
  -webkit-column-count: 2;
  column-count:         2;
  }
}
@media (max-width: 600px) {
  #lab {
  -moz-column-count:    1;
  -webkit-column-count: 1;
  column-count:         1;
  }
}
*/

/* Colcade setup */
/* Using floats */
.grid-col {
  float: left;
  width: 25%;
  display: block;
}

@media (max-width: 1290px) {
    .grid-col { width: 33.333%; }
    .grid-col--4 { display: none; }
}
@media (max-width: 960px) {
    .grid-col { width: 50%; }
    .grid-col--3 { display: none; }
}
@media (max-width: 600px) {
    .grid-col { width: 100%; }
    .grid-col--2 { display: none; }
}

#lab img {
    /* Just in case there are inline attributes */
    width: 100% !important;
    height: auto !important;
}

/** 
 * About + Blog Posts **********************************************************
 */
.long-text {
    max-width: 800px;
    width: 66%;
    margin: 0 auto;

    p {
        text-align: justify;
    }
}

@media (max-width: 700px) {        
    .long-text {
        width: 90%;
    }
}

.long-text figure {
    padding: 5pt;
}
